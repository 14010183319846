import { Language as e } from "./language.js";
import s from "./data/ui/en.json.js";
import o from "./data/ui/tr.json.js";
const m = {
  [e.EN]: s,
  [e.TR]: o
};
export {
  m as languageMessages
};
