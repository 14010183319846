import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box } from "../layout/Box.js";
const Backdrop = forwardRef(({ zIndex, background = "rgba(0, 0, 0, 0.2)", sx, ...rest }, ref) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      ...rest,
      ref,
      sx: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        overflow: "auto",
        zIndex,
        background,
        ...sx
      }
    }
  );
});
export {
  Backdrop
};
