import { jsx, jsxs } from "react/jsx-runtime";
import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useIntl } from "@reslify/i18n";
import { useScreenSize } from "../hooks/use-screen-size.js";
import { Z_INDEX } from "../constants.js";
import { Dialog } from "./Dialog.js";
import { Column } from "../layout/Column.js";
import { Body } from "../typography/Body.js";
import { Button } from "../inputs/Button.js";
const ConfirmationModal = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonVariant = "primary",
  cancelButtonVariant = "primary",
  isLoadingConfirm = false,
  isLoadingCancel = false,
  onClose
}) => {
  const { isMobile, isTablet } = useScreenSize();
  const { formatMessage } = useIntl();
  const isSmallScreen = isMobile && !isTablet;
  const confirmButtonLabel = confirmButtonText || formatMessage({
    id: "common.modal.confirm",
    defaultMessage: "Confirm"
  });
  const cancelButtonLabel = cancelButtonText || formatMessage({ id: "common.modal.cancel", defaultMessage: "Cancel" });
  return /* @__PURE__ */ jsx(Box, { zIndex: Z_INDEX.ConfirmationDialog, children: /* @__PURE__ */ jsx(Dialog, { isOpen, position: isSmallScreen ? "bottom" : "center", children: /* @__PURE__ */ jsxs(Box, { px: 4, pt: 4, pb: 3, position: "relative", children: [
    onClose && /* @__PURE__ */ jsx(Box, { position: "absolute", right: 16, top: 16, children: /* @__PURE__ */ jsx(IconButton, { onClick: onClose, children: /* @__PURE__ */ jsx(Close, {}) }) }),
    /* @__PURE__ */ jsxs(Column, { children: [
      /* @__PURE__ */ jsx(Body, { fontWeight: "bold", fontSize: 2.5, children: title }),
      message && /* @__PURE__ */ jsx(Box, { pb: 2, children: /* @__PURE__ */ jsx(Body, { children: message }) }),
      /* @__PURE__ */ jsx(Button, { onClick: onConfirm, color: confirmButtonVariant, loading: isLoadingConfirm, fullWidth: true, children: confirmButtonLabel }),
      /* @__PURE__ */ jsx(Button, { color: cancelButtonVariant, variant: "text", onClick: onCancel, loading: isLoadingCancel, fullWidth: true, children: cancelButtonLabel })
    ] })
  ] }) }) });
};
export {
  ConfirmationModal
};
