const Z_INDEX = {
  Tooltip: 1550,
  TooltipBackdrop: 1540,
  ConfirmationDialog: 1510,
  Dialog: 1500,
  DialogBackdrop: 1450,
  Sidebar: 1400,
  SidebarBackdrop: 1350,
  SpeedDial: 1300,
  SpeedDialBackdrop: 1250,
  VerticalMenu: 1200,
  VerticalMenuBackdrop: 1150,
  Taskbar: 1100
};
const PageWidth = {
  ExtraNarrow: ["100%", "444px", "400px", "400px"],
  Narrow: ["100%", "83%", "576px", "576px"],
  Normal: ["100%", "100%", "83%", "778px"],
  Wide: ["100%", "100%", "100%", "100%"],
  ExtraWide: ["100%", "100%", "100%", "100%"]
};
const TASKBAR_HEIGHT = 60;
const VERTICAL_NAV_OPEN_WIDTH = "300px";
const VERTICAL_NAV_CLOSED_WIDTH = "88px";
export {
  PageWidth,
  TASKBAR_HEIGHT,
  VERTICAL_NAV_CLOSED_WIDTH,
  VERTICAL_NAV_OPEN_WIDTH,
  Z_INDEX
};
