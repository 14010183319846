"use client";
import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { Slide, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { Z_INDEX } from "../constants.js";
import { Backdrop } from "./Backdrop.js";
const DialogContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "position"
})(({ theme, position }) => ({
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  maxWidth: position === "center" ? "600px" : "100%",
  boxShadow: theme.shadows[3],
  height: position === "fullScreen" ? "100%" : "auto",
  maxHeight: position === "fullScreen" ? "100%" : "80%",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  zIndex: Z_INDEX.Dialog,
  top: position === "center" ? "50%" : void 0,
  left: position === "center" ? "50%" : void 0,
  bottom: position === "bottom" || position === "fullScreen" ? "0" : void 0,
  borderBottomRightRadius: position === "bottom" || position === "fullScreen" ? "0" : void 0,
  borderBottomLeftRadius: position === "bottom" || position === "fullScreen" ? "0" : void 0,
  borderTopRightRadius: position === "fullScreen" ? "0" : void 0,
  borderTopLeftRadius: position === "fullScreen" ? "0" : void 0,
  transform: position === "center" ? "translate(-50%, -50%)" : void 0
}));
const Dialog = ({ isOpen, position, children }) => {
  const [contentVisible, setContentVisible] = React.useState(false);
  useEffect(() => {
    setContentVisible(isOpen);
  }, [isOpen]);
  const handleAnimationEnd = () => {
    if (!isOpen) {
      setContentVisible(false);
    }
  };
  const renderDialogContainer = () => /* @__PURE__ */ jsx(DialogContainer, { position, onAnimationEnd: handleAnimationEnd, children });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    contentVisible && /* @__PURE__ */ jsxs(Fragment, { children: [
      position !== "center" && /* @__PURE__ */ jsx(Slide, { in: isOpen, direction: "up", children: renderDialogContainer() }),
      position === "center" && /* @__PURE__ */ jsx(Fade, { in: isOpen, children: renderDialogContainer() })
    ] }),
    isOpen && /* @__PURE__ */ jsx(Backdrop, { zIndex: Z_INDEX.DialogBackdrop })
  ] });
};
export {
  Dialog
};
