"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider as ThemeProvider$1 } from "@mui/material/styles";
import React, { useMemo } from "react";
import GlobalStylesComponent from "../styles/GlobalStyles.js";
const defaultTheme = createTheme({
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Open Sans"].join(",")
  }
});
const _themeContext = React.createContext({ theme: defaultTheme });
const ThemeProvider = ({
  children,
  theme = defaultTheme,
  noSnackbar = false,
  noPhoneInput = false
}) => {
  const styles = React.useMemo(() => {
    const stylesToImport = [];
    if (!noSnackbar) {
      stylesToImport.push(React.lazy(() => import("../styles/SnackbarStyles.js")));
    }
    if (!noPhoneInput) {
      stylesToImport.push(React.lazy(() => import("../styles/ReactPhoneInputStyles.js")));
    }
    return stylesToImport;
  }, [noSnackbar, noPhoneInput]);
  const SnackbarProvider = useMemo(() => {
    return !noSnackbar ? React.lazy(() => import("./SnackbarProvider.js")) : null;
  }, [noSnackbar]);
  return /* @__PURE__ */ jsx(_themeContext.Provider, { value: { theme }, children: /* @__PURE__ */ jsxs(ThemeProvider$1, { theme, children: [
    /* @__PURE__ */ jsx(CssBaseline, {}),
    /* @__PURE__ */ jsx(GlobalStylesComponent, {}),
    styles.map((StyleComponent, index) => /* @__PURE__ */ jsx(React.Suspense, { fallback: null, children: /* @__PURE__ */ jsx(StyleComponent, {}) }, index)),
    SnackbarProvider ? /* @__PURE__ */ jsx(React.Suspense, { fallback: null, children: /* @__PURE__ */ jsx(SnackbarProvider, { children }) }) : children
  ] }) });
};
const useTheme = () => React.useContext(_themeContext).theme;
export {
  ThemeProvider,
  defaultTheme,
  useTheme
};
