// theme.js or theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            fontSize: '18px',
            color: '#0047FD',
            fontWeight: 600,
            textTransform: 'none'
          }
        },
        {
          props: { variant: 'contained' },
          style: {
            fontSize: [2, 2, 2.23, 2.5, 2.75].map((size) => `${size * 8}px`),
            lineHeight: [2, 2, 2.23, 2.5, 2.75]
              .map((size) => size * 1.5)
              .map((size) => size * 8)
              .map((size) => `${size}px`),
            fontWeight: 600,
            textTransform: 'none',
            borderRadius: 8,
            backgroundColor: '#1A61FF',
            '&:hover': {
              backgroundColor: '#0047FD'
            }
          }
        }
      ]
    }
  }
});

export default theme;
