import { jsx } from "react/jsx-runtime";
import { FormContextProvider } from "./FormContextProvider.js";
import { ThemeProvider } from "./ThemeProvider.js";
import { ModalProvider } from "./ModalProvider.js";
const UICoreProvider = ({ children, themeOptions }) => {
  return /* @__PURE__ */ jsx(ThemeProvider, { ...themeOptions, children: /* @__PURE__ */ jsx(ModalProvider, { children: /* @__PURE__ */ jsx(FormContextProvider, { children }) }) });
};
export {
  UICoreProvider
};
