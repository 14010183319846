import { jsx } from "react/jsx-runtime";
import { Box } from "../layout/Box.js";
const Body = ({ fontSize = 2, ref, lineHeight = 3, noSelect, ...rest }) => /* @__PURE__ */ jsx(
  Box,
  {
    ...rest,
    fontSize: fontSize * 8,
    lineHeight: `${lineHeight * 8}px`,
    sx: {
      userSelect: noSelect ? "none" : void 0
    }
  }
);
export {
  Body
};
