"use client";
import { jsx, jsxs } from "react/jsx-runtime";
import { Close } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import styled from "styled-components";
import { useIntl } from "@reslify/i18n";
import { Dialog } from "./Dialog.js";
import { useScreenSize } from "../hooks/use-screen-size.js";
import { Button } from "../inputs/Button.js";
import { Flex } from "../layout/Flex.js";
import { Body } from "../typography/Body.js";
const CloseIconContainer = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: ${({ theme: { spacing } }) => spacing(3)};
  right: ${({ theme: { spacing } }) => spacing(3)};
  width: 18px;
  height: 18px;
`;
const Modal = ({
  isOpen,
  title,
  onConfirm,
  onCancel,
  canClose,
  children,
  confirmButtonVariant = "primary",
  confirmButtonText,
  cancelButtonVariant = "primary",
  cancelButtonText,
  isLoadingConfirm = false,
  isLoadingCancel = false,
  confirmDisabled = false
}) => {
  const { isMobile } = useScreenSize();
  const { formatMessage } = useIntl();
  const confirmButtonLabel = confirmButtonText || formatMessage({
    id: "common.modal.confirm",
    defaultMessage: "Confirm"
  });
  const cancelButtonLabel = cancelButtonText || formatMessage({ id: "common.modal.cancel", defaultMessage: "Cancel" });
  return /* @__PURE__ */ jsx(Dialog, { isOpen, position: isMobile ? "fullScreen" : "center", children: /* @__PURE__ */ jsxs(Flex, { px: 4, pt: 4, pb: 3, style: { overflow: "hidden", height: "100%" }, flexDirection: "column", children: [
    canClose && /* @__PURE__ */ jsx(CloseIconContainer, { onClick: onCancel, children: /* @__PURE__ */ jsx(Close, {}) }),
    /* @__PURE__ */ jsx(Body, { mb: 3, fontWeight: "bold", fontSize: 2.5, children: title }),
    /* @__PURE__ */ jsx(Body, { style: { overflowY: "auto" }, children }),
    /* @__PURE__ */ jsx(Box, { py: 3, children: /* @__PURE__ */ jsx(Divider, {}) }),
    /* @__PURE__ */ jsxs(Flex, { justifyContent: "flex-end", children: [
      /* @__PURE__ */ jsx(Box, { mr: 1.5, children: /* @__PURE__ */ jsx(Button, { onClick: onConfirm, color: confirmButtonVariant, loading: isLoadingConfirm, disabled: confirmDisabled, children: confirmButtonLabel }) }),
      onCancel && /* @__PURE__ */ jsx(Button, { color: cancelButtonVariant, onClick: onCancel, variant: "text", loading: isLoadingCancel, children: cancelButtonLabel })
    ] })
  ] }) });
};
export {
  Modal
};
