"use client";
import { jsx } from "react/jsx-runtime";
import { GlobalStyles } from "@mui/material";
import React from "react";
import { Z_INDEX } from "../constants.js";
const injectOpenSans = () => {
  const link = document.createElement("link");
  link.href = "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};
const GlobalStylesComponent = ({
  fontFamily = "'Open Sans', sans-serif"
  /* OpenSans */
}) => {
  React.useEffect(() => {
    if (fontFamily === "'Open Sans', sans-serif") {
      injectOpenSans();
    }
  }, [fontFamily]);
  return /* @__PURE__ */ jsx(
    GlobalStyles,
    {
      styles: (theme) => ({
        html: {
          height: "100%"
        },
        "#root": {
          height: "100%",
          display: "flex",
          flexDirection: "column"
        },
        body: {
          height: "100%",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          fontFamily,
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale"
        },
        a: {
          textDecoration: "none",
          color: theme.palette.primary.main,
          backgroundColor: "transparent"
        },
        ".MuiAlert-icon": {
          color: "black !important"
        },
        ".MuiMenu-root": {
          zIndex: `${Z_INDEX.Sidebar} !important`
        }
      })
    }
  );
};
export {
  GlobalStylesComponent as default
};
