"use client";
import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box } from "./Box.js";
const Flex = forwardRef(({ sx, ...rest }, ref) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      ...rest,
      ref,
      sx: {
        display: "flex",
        boxSizing: "border-box",
        ...sx
      }
    }
  );
});
export {
  Flex
};
