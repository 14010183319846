import i18nConfigs from '../../i18n/config.json';

export type CountryCode = 'us' | 'au' | 'tr';

export interface Language {
  code: CountryCode;
  hrefLang: string;
  name: string;
  localName: string;
  langDir: Direction;
  dateFormat: string;
}

export type Direction = 'rtl' | 'ltr' | 'auto';

export type Languages = Record<CountryCode, Language>;
export const defaultCountryCode: CountryCode = 'us';

// same data as in the `config.json` but indexed by language code
const languages: Languages = i18nConfigs.reduce((result, config) => {
  return { ...result, [config.code]: config };
}, {} as Languages);

export const supportedCountries = Object.keys(languages) as Array<CountryCode>;

export const languagePresentation: Record<CountryCode, string> = {
  us: 'United States (English)',
  au: 'Australia (English)',
  tr: 'Türkiye (Türkçe)'
};
