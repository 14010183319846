"use client";
import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { useIntl } from "@reslify/i18n";
import { capitalize } from "@reslify/utils";
import { ConfirmationModal } from "../feedback/ConfirmationModal.js";
import { Modal } from "../feedback/Modal.js";
const ModalContext = React.createContext({
  showDefaultModal: () => {
  },
  hideDefaultModal: () => {
  },
  showConfirmationModal: () => {
  },
  hideConfirmationModal: () => {
  },
  showDeleteModal: () => {
  },
  hideDeleteModal: () => {
  }
});
const useModal = () => React.useContext(ModalContext);
const ModalProvider = ({ children }) => {
  const { formatMessage } = useIntl();
  const [loadingStates, setLoadingStates] = useState({
    isLoadingConfirm: false,
    isLoadingCancel: false,
    isLoadingDelete: false
  });
  const [modalState, setModalState] = useState({
    isDefaultModalOpen: false,
    isConfirmationModalOpen: false,
    isDeleteModalOpen: false
  });
  const openModal = (type, options) => {
    setModalState((prev) => ({
      ...prev,
      [type]: options,
      [`is${capitalize(type.replace("Options", ""))}Open`]: true
    }));
  };
  const closeModal = (type) => {
    setModalState((prev) => ({
      ...prev,
      [`is${capitalize(type.replace("Options", ""))}Open`]: false
    }));
  };
  const handleLoading = async (loadingType, action) => {
    if (loadingStates[loadingType]) return;
    setLoadingStates((prev) => ({ ...prev, [loadingType]: true }));
    try {
      await (action == null ? void 0 : action());
    } finally {
      setLoadingStates((prev) => ({ ...prev, [loadingType]: false }));
    }
  };
  const contextValue = {
    showDefaultModal: (options) => openModal("defaultModalOptions", options),
    hideDefaultModal: () => closeModal("defaultModalOptions"),
    showConfirmationModal: (options) => openModal("confirmationModalOptions", options),
    hideConfirmationModal: () => closeModal("confirmationModalOptions"),
    showDeleteModal: (options) => openModal("deleteModalOptions", options),
    hideDeleteModal: () => closeModal("deleteModalOptions")
  };
  return /* @__PURE__ */ jsx(ModalContext.Provider, { value: contextValue, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    children,
    modalState.confirmationModalOptions && /* @__PURE__ */ jsx(
      ConfirmationModal,
      {
        isOpen: modalState.isConfirmationModalOpen,
        title: modalState.confirmationModalOptions.title,
        message: modalState.confirmationModalOptions.message,
        onConfirm: async () => await handleLoading("isLoadingConfirm", modalState.confirmationModalOptions.onConfirm),
        onCancel: async () => await handleLoading("isLoadingCancel", modalState.confirmationModalOptions.onCancel),
        onClose: modalState.confirmationModalOptions.onClose,
        cancelButtonText: modalState.confirmationModalOptions.cancelButtonText,
        cancelButtonVariant: modalState.confirmationModalOptions.cancelButtonVariant,
        confirmButtonVariant: modalState.confirmationModalOptions.confirmButtonVariant,
        confirmButtonText: modalState.confirmationModalOptions.confirmButtonText,
        isLoadingConfirm: loadingStates.isLoadingConfirm,
        isLoadingCancel: loadingStates.isLoadingCancel
      }
    ),
    modalState.deleteModalOptions && /* @__PURE__ */ jsx(
      ConfirmationModal,
      {
        isOpen: modalState.isDeleteModalOpen,
        title: modalState.deleteModalOptions.title,
        message: modalState.deleteModalOptions.message,
        onConfirm: async () => await handleLoading("isLoadingDelete", modalState.deleteModalOptions.onDelete),
        onCancel: async () => await handleLoading("isLoadingCancel", modalState.deleteModalOptions.onCancel),
        cancelButtonText: formatMessage({ id: "common.modal.cancel", defaultMessage: "Cancel" }),
        confirmButtonVariant: "error",
        confirmButtonText: formatMessage({ id: "common.modal.delete", defaultMessage: "Delete" }),
        isLoadingConfirm: loadingStates.isLoadingDelete
      }
    ),
    modalState.defaultModalOptions && /* @__PURE__ */ jsx(
      Modal,
      {
        isOpen: modalState.isDefaultModalOpen,
        title: modalState.defaultModalOptions.title,
        onConfirm: async () => await handleLoading("isLoadingConfirm", modalState.defaultModalOptions.onConfirm),
        onCancel: async () => await handleLoading("isLoadingCancel", modalState.defaultModalOptions.onCancel),
        confirmButtonVariant: modalState.defaultModalOptions.confirmButtonVariant,
        confirmButtonText: modalState.defaultModalOptions.confirmButtonText,
        cancelButtonText: modalState.defaultModalOptions.cancelButtonText,
        canClose: modalState.defaultModalOptions.canClose,
        isLoadingConfirm: loadingStates.isLoadingConfirm,
        isLoadingCancel: loadingStates.isLoadingCancel,
        children: modalState.defaultModalOptions.children
      }
    )
  ] }) });
};
export {
  ModalProvider,
  useModal
};
