"use client";
import { useState, useCallback, useEffect } from "react";
import { useTheme } from "../providers/ThemeProvider.js";
const useScreenSize = () => {
  const canUseWindow = typeof window !== "undefined";
  const [screenSize, setScreenSize] = useState(canUseWindow ? window.innerWidth : 0);
  const onResize = useCallback(() => {
    if (canUseWindow) setScreenSize(window.innerWidth);
  }, [canUseWindow]);
  useEffect(() => {
    if (!canUseWindow) return;
    window.addEventListener("resize", onResize, false);
    return () => {
      if (!canUseWindow) return;
      window.removeEventListener("resize", onResize, false);
    };
  }, [onResize, canUseWindow]);
  const breakpoints = useTheme().breakpoints.values;
  const isMobile = screenSize <= breakpoints.sm;
  const isTablet = screenSize > breakpoints.sm && screenSize <= breakpoints.md;
  const isDesktop = screenSize > breakpoints.md;
  return {
    isMobile,
    isTablet,
    isDesktop,
    screenSize,
    breakpoints
  };
};
export {
  useScreenSize
};
