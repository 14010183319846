"use client";
import { jsx as n } from "react/jsx-runtime";
import s, { useState as i, useCallback as p, useMemo as d } from "react";
import { IntlProvider as x } from "react-intl";
import { Language as r } from "../generated/language.js";
import { languageMessages as o } from "../generated/language-messages.js";
const S = {
  number: {
    USD: {
      style: "currency",
      currency: "USD"
    }
  }
}, a = s.createContext({
  language: r.EN,
  messages: {},
  switchToLanguage: () => {
    throw new Error("switchToLanguage is not implemented");
  }
}), w = ({
  language: c = r.EN,
  children: g
}) => {
  const [e, u] = i(c), t = p((m) => {
    u(m);
  }, []), l = d(() => ({
    language: e,
    messages: o[e],
    switchToLanguage: t
  }), [e, t]);
  return /* @__PURE__ */ n(a.Provider, { value: l, children: /* @__PURE__ */ n(x, { locale: e, messages: o[e], formats: S, children: g }) });
}, I = () => s.useContext(a);
export {
  a as IntlContextSync,
  w as IntlProviderSync,
  I as useIntlSyncContext
};
