import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { forwardRef } from "react";
import { Flex } from "./Flex.js";
const Column = forwardRef(({ space = 2, children, ...rest }, ref) => {
  const items = (Array.isArray(children) ? children : [children]).filter((element) => Boolean(element));
  return /* @__PURE__ */ jsx(Flex, { flexDirection: "column", ...rest, ref, children: items.map((child, index) => /* @__PURE__ */ jsx(Box, { mb: index + 1 === items.length ? 0 : space, children: child }, index)) });
});
export {
  Column
};
