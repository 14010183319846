"use client";
import { jsxs, jsx } from "react/jsx-runtime";
import { Button as Button$1 } from "@mui/material";
import React, { useState, useCallback } from "react";
import ButtonLoader from "../feedback/ButtonLoader.js";
import { useIsMountedRef } from "../hooks/use-is-mounted-ref.js";
import { Flex } from "../layout/Flex.js";
var ButtonFormat = /* @__PURE__ */ ((ButtonFormat2) => {
  ButtonFormat2["Link"] = "link";
  return ButtonFormat2;
})(ButtonFormat || {});
const CustomizedButton = React.forwardRef(
  ({ loading, async, onClick, variant = "contained", children, ...rest }, ref) => {
    const isMounted = useIsMountedRef();
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const handleClick = useCallback(
      async (ev) => {
        if (loading || isLocalLoading || !onClick) return;
        if (async) {
          setIsLocalLoading(true);
          try {
            await onClick(ev);
          } finally {
            if (isMounted.current) {
              setIsLocalLoading(false);
            }
          }
        } else {
          onClick(ev);
        }
      },
      [onClick, loading, async, isMounted, isLocalLoading]
    );
    const isLoading = loading || isLocalLoading;
    return /* @__PURE__ */ jsxs(Button$1, { onClick: handleClick, variant, ref, ...rest, children: [
      /* @__PURE__ */ jsx(Flex, { justifyContent: "center", style: { visibility: isLoading ? "hidden" : "visible", width: "100%" }, children }),
      (isLoading || isLocalLoading) && /* @__PURE__ */ jsx(ButtonLoader, {})
    ] });
  }
);
const Button = React.forwardRef(({ format, ...rest }, ref) => {
  if (format === "link") {
    return /* @__PURE__ */ jsx(CustomizedButton, { ...rest, ref, size: "small", variant: "text" });
  }
  return /* @__PURE__ */ jsx(CustomizedButton, { ...rest, ref });
});
export {
  Button,
  ButtonFormat
};
