import { jsx } from "react/jsx-runtime";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
const LoaderContainer = styled("div")`
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ButtonLoader = () => {
  return /* @__PURE__ */ jsx(LoaderContainer, { children: /* @__PURE__ */ jsx(CircularProgress, { size: 18, color: "inherit" }) });
};
export {
  ButtonLoader as default
};
